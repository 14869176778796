<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.25 7C22.25 9.8995 19.8995 12.25 17 12.25C14.1005 12.25 11.75 9.8995 11.75 7C11.75 4.10051 14.1005 1.75 17 1.75C19.8995 1.75 22.25 4.10051 22.25 7ZM17.8414 4.99479C17.8414 5.45951 17.4647 5.83625 17 5.83625C16.5353 5.83625 16.1585 5.45951 16.1585 4.99479C16.1585 4.53006 16.5353 4.15333 17 4.15333C17.4647 4.15333 17.8414 4.53006 17.8414 4.99479ZM19.0052 4.99479C19.0052 6.10223 18.1074 7 17 7C15.8925 7 14.9948 6.10223 14.9948 4.99479C14.9948 3.88734 15.8925 2.98958 17 2.98958C18.1074 2.98958 19.0052 3.88734 19.0052 4.99479ZM14.9568 10.75C14.9568 9.66188 15.9118 8.70687 17 8.70687C18.0881 8.70687 19.0431 9.66188 19.0431 10.75H20.2068C20.2068 9.01916 18.7308 7.54312 17 7.54312C15.2691 7.54312 13.7931 9.01916 13.7931 10.75H14.9568ZM7.33331 10.6667C7.33331 8.40848 8.93728 6.5249 11.0681 6.09308C11.0233 6.38886 11 6.69174 11 7.00005C11 7.16162 11.0064 7.32171 11.0189 7.48007C9.65678 7.89893 8.66664 9.16718 8.66664 10.6667V12.4174C8.66664 13.1886 8.53321 13.9491 8.27665 14.6667H15.7233C15.5057 14.0581 15.3767 13.4187 15.3425 12.7682C15.7761 12.8925 16.2298 12.9693 16.6977 12.9926C16.7604 13.5731 16.9178 14.1386 17.1626 14.6667L17.1802 14.7041C17.2649 14.8832 17.3597 15.0578 17.4643 15.2273C17.525 15.3255 17.589 15.4221 17.6562 15.5166L17.6895 15.5635C17.8197 15.7465 17.6888 16 17.4643 16H17.0756H16.9765H16.4422H16.3751H7.6249H7.55776H7.02349H6.92434H6.53566C6.31112 16 6.18029 15.7465 6.31042 15.5635L6.34372 15.5166C6.41097 15.4221 6.47497 15.3255 6.53566 15.2273C6.64028 15.0578 6.73508 14.8832 6.81978 14.7041L6.8373 14.6667C7.16241 13.9654 7.33331 13.1982 7.33331 12.4174V10.6667ZM12 18.6667C10.7574 18.6667 9.71336 17.8168 9.41733 16.6667H10.845C11.0756 17.0652 11.5065 17.3334 12 17.3334C12.4935 17.3334 12.9244 17.0652 13.1549 16.6667H14.5826C14.2866 17.8168 13.2425 18.6667 12 18.6667Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, default: 24 },
  },
};
</script>
