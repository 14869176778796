<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 2C12.5523 2 13 2.44772 13 3V3.07089C16.3923 3.55612 19 6.47353 19 10V12.6261C19 13.7973 19.2563 14.948 19.744 16L19.7703 16.0561C19.8973 16.3247 20.0395 16.5867 20.1965 16.8409C20.2875 16.9883 20.3835 17.1331 20.4844 17.2749L20.5343 17.3452C20.7295 17.6196 20.5333 18 20.1965 18H19.6134H19.4647H18.6633H18.5626H5.43738H5.33667H4.53526H4.38654H3.80353C3.46671 18 3.27047 17.6196 3.46566 17.3452L3.51561 17.2749C3.61648 17.1331 3.71248 16.9883 3.80353 16.8409C3.96046 16.5867 4.10265 16.3247 4.2297 16.0561L4.25598 16C4.74365 14.948 4.99999 13.7973 4.99999 12.6261V10C4.99999 6.47353 7.6077 3.55612 11 3.07089V3C11 2.44772 11.4477 2 12 2ZM6.99999 10C6.99999 7.23858 9.23857 5 12 5C14.7614 5 17 7.23858 17 10V12.6261C17 13.7828 17.2001 14.9236 17.585 16H6.415C6.79985 14.9236 6.99999 13.7828 6.99999 12.6261V10ZM12 22C10.1362 22 8.57007 20.7252 8.12603 19H10.2676C10.6134 19.5978 11.2597 20 12 20C12.7403 20 13.3866 19.5978 13.7324 19H15.874C15.43 20.7252 13.8639 22 12 22Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, default: 24 },
  },
};
</script>
