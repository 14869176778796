<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5 7.77294C11.1259 7.86472 10.7602 8.01298 10.4346 8.23756C9.77646 8.69142 9.35714 9.41083 9.35714 10.3333C9.35714 11.2887 9.91061 11.8999 10.4985 12.2657C11.0111 12.5847 11.6526 12.7839 12.1449 12.9369L12.2029 12.9549C12.7752 13.1329 13.1743 13.264 13.4449 13.4324C13.5654 13.5074 13.6101 13.561 13.6247 13.5831L13.6253 13.584C13.6319 13.594 13.6428 13.6103 13.6428 13.6667C13.6428 13.9283 13.5475 14.0762 13.3987 14.1875C13.2187 14.3221 12.9093 14.4309 12.499 14.4313C11.8473 14.432 11.1686 14.1601 10.7593 13.6826L9.24075 14.9842C9.84602 15.6903 10.6665 16.1256 11.5 16.317V17H13.5V16.306C13.8908 16.2039 14.2666 16.0359 14.5966 15.7891C15.235 15.3115 15.6428 14.5776 15.6428 13.6667C15.6428 12.7113 15.0893 12.1001 14.5015 11.7343C13.9889 11.4153 13.3474 11.2161 12.8551 11.0632L12.7971 11.0452C12.2247 10.8671 11.8257 10.736 11.5551 10.5676C11.4346 10.4926 11.3898 10.439 11.3752 10.4169L11.3747 10.416C11.368 10.4061 11.3571 10.3897 11.3571 10.3333C11.3571 10.0832 11.4409 9.97305 11.57 9.88401C11.7474 9.76166 12.0685 9.65834 12.5228 9.64797C13.2444 9.63149 14.0016 9.85511 14.4266 10.1526L15.5734 8.51407C15.0001 8.1128 14.2562 7.84253 13.5 7.7208V7H11.5V7.77294Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 7V3C5 2.44772 5.44772 2 6 2H18C18.5523 2 19 2.44772 19 3V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V17H4V15H5V13H4V11H5V9H4V7H5ZM7 7V4H17V20H7V17H8V15H7V13H8V11H7V9H8V7H7Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
